.footer {
    width: 100%;
    height: 200px;
    background-color: #121619;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.socialMedia svg {
    color: white;
    margin: 10px;
    font-size: 70px;
    cursor: pointer;
}

.footer p {
    color: white;
}