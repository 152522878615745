.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-attachment: fixed;
    background-size: cover;
  }

.projects .projectTitle {
  color: white;
  display: flex;
  justify-content: center;
  font-family: valorant;
  font-size: 60px;
}

.projectList {
  width: 500px;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

/* PROJECT ITEMS STYLING */

.projectItems {
  background-color: white;
  border-radius: 15px;
  width: 600px;
  height: auto;
  margin-left: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}
.projectItems .projectImg {
  width: 100%;
  height: 400px;
  border-radius: 15px;
}
.projectUrl {
  color: red;
  display: flex;
  align-items: center;
}

.projectItems:hover {
  box-shadow: 0px 3px 15px rgb(255, 0, 0);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.projectItems div {
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.projectItems h1 {
  display: flex;
  justify-content: center;
  font-family: valorant;
  /* margin-left: 20px; */
}
.projectItems p {
  font-family: cursive;
  margin-left: 20px;
}

 @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }