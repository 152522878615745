.home {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .headerContainer {
    width: auto;
    margin-left: 50px;
    color: white;
  }
  
  .headerContainer h1 {
    
    justify-content: center;
    align-items: center;
    font-size: 90px;
    height: 60px;
    font-weight: 50;
  }
  
  .headerContainer p {
    font-family: cursive;
    padding: 0;
    font-size: 40px;
    font-weight: lighter;
    margin-right: 100px;
  }
  
  .headerContainer .resumetext {
    font-size: 20px;
    margin-left: 25px;
    margin-top: 140px;
  }

  .headerContainer button {
    color: black;
    border: 2px solid red;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: #121619;
    height: 50px;
    width: 180px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  .headerContainer button:hover {
    /* color: black; */
    background-color: red;
    border: 2px solid red;
    cursor: pointer;
    transition: auto;
  }
  
  @media only screen and (max-width: 650px) {
    .home {
      justify-content: center;
      align-items: center;
    }

    .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #121619;
      color: white;
    }
  
    .headerContainer h1 {
      font-size: 40px;
      height: 30px;
      color: white;
    }
  
    .headerContainer p {
      font-size: 30px;
      color: white;
    }
  
    .headerContainer button {
      background-color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: red;
    }
  }